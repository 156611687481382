























import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Category, Deck, DeckSearchRequest, PagedResult } from '@shared/models';
import DeckItemList from '@client/views/products/decks/DeckItemList.vue';

@Component({
	components: {
		DeckItemList,
	},
})
export default class CollectionDetails extends BaseView {

	public modelId: number = null;
	public model: Category = new Category();
	public list: PagedResult<Deck> = new PagedResult<Deck>();
	public pageIndex: number = 0;

	/** Fires when this view is mounted */
	public async mounted() {
		await this.refresh();
	}

	public async refresh() {
		this.loading = true;
		try {
			this.modelId = parseInt(this.$route.params.id, null);
			this.model = await this.$api.categoryService.get(this.modelId);
			this.list = await this.$api.deckService.search(new DeckSearchRequest({ categoryIds: this.model.id.toString(), sortBy: 'year', sortDirection: 'asc', pageSize: 60, pageIndex: this.pageIndex }));
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while loading this collection');
		} finally {
			this.loading = false;
		}
	}

}

