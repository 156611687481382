

















import { Component, Prop, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { BaseView } from '@shared/views/BaseView';
import { Deck, DeckItemGroup, PagedResult } from '@shared/models';
import DeckItem from './DeckItem.vue';

@Component({
	components: {
		DeckItem,
	},
})
export default class DeckItemList extends BaseView {

	/** The initial list of decks that will get turned into a grouped list */
	@Prop({ type: Object as PropType<Deck> })
	public list: PagedResult<Deck>;

	/** A value indicating if the company should be shown under each deck */
	@Prop({ default: true })
	public showCompany: boolean;

	/** A value indicating if the year should be shown under each deck */
	@Prop({ default: true })
	public showYear: boolean;

	@Prop({ default: true})
	public group: boolean;

	@Prop({ default: 'center'})
	public justify: string;

	/** The final grouped list of decks */
	public groupedList: DeckItemGroup[] = [];

	/** Watches the list property and turns whatevers in it into a groupped list */
	@Watch('list', { immediate: true, deep: true })
	public groupDecks() {
		this.groupedList = [];
		if (this.list.pager.totalResults > 0) {
			for (const item of this.list.results) {
				let existing = this.groupedList.find((x) => x.title === item.year.toString() );
				if (!existing) { 
					existing = new DeckItemGroup({ title: item.year.toString(), list: [] });
					this.groupedList.push(existing);
				}
				existing.list.push(item);
			}
		}
	}

}

